<template>
<div class="stu-exam-detail">
  <MyBreadcrumb></MyBreadcrumb>
  <div class="stu-exam-detail-cont" v-if="user.id">
    <div class="top-tit">{{user.nickname}}的试卷分析（{{train.name}}）</div>
    <div class="info">
      <div class="info-l">
        <div>
          <div class="num">{{ train.data_count }}</div>
          <div>总题数</div>
        </div>
        <div>
          <div class="num">{{ trainUser.right_count }}</div>
          <div>答对数</div>
        </div>
        <div>
          <div class="num">{{ trainUser.have_finished - trainUser.right_count }}</div>
          <div>答错数</div>
        </div>
        <div>
          <div class="num">{{ trainUser.have_finished != 0 ? (trainUser.right_count/trainUser.have_finished * 100).toFixed(2) : 0 }}%</div>
          <div>准确率</div>
        </div>
      </div>
      <div class="info-r">
        <div class="info-r-top">
          <div class="tit">答题得分:</div>
          <div><span>{{trainUser.score}}分</span>/{{train.total_score}}分</div>
        </div>
        <div class="progress-bj"><div class="progress-val" :style="{width:(trainUser.score / train.total_score * 100) + '%'}"></div></div>
      </div>
      <div class="info-r">
        <div class="info-r-top">
          <div class="tit">答题用时:</div>
          <div><span>{{ (trainUser.use_time /60).toFixed(2) }}分钟</span>/{{train.timelong}}分钟</div>
        </div>
        <div class="progress-bj"><div class="progress-val" :style="{width:(trainUser.use_time /60 / train.timelong * 100) + '%'}"></div></div>
      </div>
    </div>
    <div class="tit m20">题型得分情况:</div>
    <Table :columns="columns" :data="dataList"></Table>
    <div class="tit m20">知识掌握程度:</div>
    <Table :columns="nodesColumns" :data="nodesDataList"></Table>
    <div class="tit m20">答题卡:</div>
    <div class="answer-sheet">
      <div class="answer-sheet-top">
        <div class="answer-sheet-top-item">
          <span class="bgC_g"></span>
          <p>答对</p>
        </div>
        <div class="answer-sheet-top-item">
          <span class="bgC_r"></span>
          <p>答错</p>
        </div>
        <div class="answer-sheet-top-item">
          <span></span>
          <p>未答</p>
        </div>
      </div>
      <div class="answer-sheet-list">
        <div :class="{'bgC_r' :item.userData && item.userData.is_right == '0','bgC_g' :item.userData && item.userData.is_right == '1'} " v-for="(item,index) in answerCardList" :key="item.id">{{ (index +1) < 10 ? '0' + (index + 1) : (index + 1)}}</div>
      </div>
      <div class="answer-btns">
        <div class="answer-sheet-btn" @click="goResult">查看答题详情</div>
        <div class="answer-sheet-btn" v-if="trainUser.status == 4" @click="goMark">去批卷</div>
      </div>

    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "stuExamDetail",
  data(){
    return{
      columns:[
        {
          title: '题型',
          key: 'name'
        },
        {
          title: '总题数',
          key: 'data_count'
        },
        {
          title: '正确题数',
          key: 'right_count'
        },
        {
          title: '总分',
          key: 'total_score'
        },
        {
          title: '得分',
          key: 'right_score'
        },
        {
          title: '正确率',
          key: 'right_rate',
          render:(h,params)=>{
            return h('span',(params.row.right_rate + '%'))
          }
        },
      ],
      dataList:[
      ],
      nodesColumns:[
        {
          title: '知识点',
          key: 'label_name'
        },
        {
          title: '总题数',
          key: 'total'
        },
        {
          title: '正确题数',
          key: 'right_count'
        },
        {
          title: '掌握程度',
          render:(h,params)=>{
            return h('span',(params.row.total == '0' ? '0' : ((params.row.right_count / params.row.total * 100).toFixed(2)) + '%'))
          }
        },
      ],
      nodesDataList:[
      ],
      trainUserId:'',
      onlyWrong:'',
      train:{},
      trainUser:{},
      answerCardList:[],
      userId:'',
      user:{},
      role:'',
    }
  },
  mounted(){
    this.trainUserId = this.$route.query.id;
    this.role = this.$route.query.role || '';
    // this.userId = this.$route.query.userId;
    this.getData();
  },
  methods:{
    getData(){
      let params = {
        action:'trainUserReport',
        train_user_id:this.trainUserId,
        only_wrong:this.onlyWrong ? '1' : '0',

      };
      this.answerCardLoading = true;
      this.api.dataset.trainExecute(params).then((res)=>{
        this.train = res.train;
        this.trainUser = res.train_user;
        this.answerCardList = res.data_list;
        this.user = res.user;
        this.dataList = res.dataTypeList;
        this.nodesDataList = res.keypointList;

        // this.setOption1();
        // this.setOption2();
        // this.setOption3();
        // this.setOption4();
      })
    },
    goResult(){
      this.$router.push({
        path:'/teacher/trainingcamp/exam',
        query:{
          id:this.train.id,
          isResult:true,
          role:this.role,
          trainUserId:this.trainUser.id,
          from:'analysis'
        }
      })
    },
    goMark(data){
      this.$router.push({
        path:'/teacher/trainingcamp/exam',
        query:{
          id:this.train.id,
          isResult:true,
          isMark:true,
          user_id:this.user.id
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.stu-exam-detail{
  margin: 20px;
  .stu-exam-detail-cont{
    margin-top: 20px;
    padding: 20px;
    background-color: #FFFFFF;

    .top-tit{
      font-size: 16px;
      color: #666666;
      height: 34px;
      border-bottom: 1px solid #EEEEEE;
    }
    .info{
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      .info-l{
        padding: 20px;
        flex: 3;
        height: 98px;
        background: #F6F7FA;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        >div{
          flex: 1;
          text-align: center;
          font-size: 14px;
          color: #666666;
        }
        .num{
          margin-bottom: 4px;
          font-size: 20px;
          color: #333333;
        }
      }
      .info-r{
        padding: 20px;
        margin-left: 30px;
        flex: 2;
        height: 98px;
        background: #F6F7FA;
        border-radius: 6px;
        .info-r-top{
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          color:#333333;
          >div{
            >span{
              font-size: 16px;
              font-weight: bold;
              color: #4A79FF;
            }
          }
        }
      }
    }
    .answer-sheet{
      padding: 20px;
      background: #F6F7FA;
      border-radius: 6px;

      .answer-sheet-top{
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .answer-sheet-top-item{
          margin-right: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 14px;
          color: #666666;
          >span{
            margin-right: 10px;
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #E0E0E0;
          }
        }
      }
    }
    .answer-sheet-list{
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      >div{
        margin-right: 20px;
        margin-bottom: 10px;
        width: 30px;
        height: 30px;
        background: #E0E0E0;
        border-radius: 50%;
        text-align: center;
        line-height: 30px;
        font-size: 14px;
        color: #FFFFFF;
      }
    }
    .answer-btns{
      display: flex;
      justify-content: flex-start;
    }
    .answer-sheet-btn{
      margin-right: 10px;
      margin-top: 10px;
      width: 112px;
      height: 30px;
      line-height: 30px;
      background: #4A79FF;
      border-radius: 4px;
      font-size: 14px;
      color: #FFFFFF;
      text-align: center;
      cursor: pointer;
    }
  }
  .tit{
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }
  .progress-bj{
    flex: 1;
    height: 10px;
    background: #EEEEEE;
    border-radius: 5px;
    position: relative;
    .progress-val{
      width: 70%;
      height: 10px;
      background: #4A79FF;
      border-radius: 5px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .m20{
    margin: 20px 0;
  }
  .bgC_g{
    background: #54C99F !important;
  }
  .bgC_r{
    background: #FC605C !important;
  }
}
</style>
